import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Col from './layout/Col';
import Row from './layout/Row';
import useSiteMetadata from '../hooks/useSiteMetadata';
import PreparedMonogram from '../images/logos/prepared-monogram.svg';

const Footer: React.FC = () => {
    const meta = useSiteMetadata();
    const { title, email, bookingUrl } = meta || {};
    const today = new Date();
    const isWeekday = today.getDay() >= 1 && today.getDay() <= 5;

    return (
        <footer className="w-full">
            <div className="bg-darkBlue text-white">
                <div>
                    <Row cols={{ sm: 1, md: 6, lg: 12 }} className="container pt-16 lg:pt-20 lg:pb-4">
                        <Col md={{ span: 6, start: 1 }} lg={{ span: 1, start: 1 }} className="mb-16 lg:mb-0">
                            <figure className="mt-2 relative overflow-hidden">
                                <PreparedMonogram className="h-16 lg:h-20" />
                            </figure>
                        </Col>
                        <Col md={{ span: 6, start: 1 }} lg={{ span: 7, start: 6 }}>
                            <h2 className="title mb-12 xl:mb-14">Lass uns deine Brand voranbringen.</h2>
                            <div className="flex flex-wrap items-center">
                                <figure className="relative mr-4 overflow-hidden">
                                    <StaticImage
                                        src="../images/tim.jpg"
                                        alt="Tim, Dein Ansprechpartner"
                                        placeholder="blurred"
                                        quality={100}
                                        className="w-14 h-14 rounded-full"
                                    />
                                </figure>
                                <div className="mr-12">
                                    <p className="text-base">Tim Mittermüller</p>
                                    <p className="text-base opacity-60">Dein Ansprechpartner</p>
                                </div>
                                <div className="mt-6 lg:mt-0">
                                    <a
                                        href={`mailto:${email || ''}`}
                                        className="rounded-btn border group hover:text-black hover:bg-white mr-4 mb-4 md:mb-0"
                                    >
                                        Mail schreiben
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" className="-rotate-90 stroke-white group-hover:stroke-black">
                                            <g strokeWidth="1.5" fill="none" fillRule="evenodd">
                                                <path d="M13 3.77V13H3.77M13 13 1 1" />
                                            </g>
                                        </svg>
                                    </a>
                                    <a
                                        href={bookingUrl || ''}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="rounded-btn border group hover:text-black hover:bg-white"
                                    >
                                        {isWeekday && (
                                            <span className="w-3 h-3 rounded-full bg-green" />
                                        )}
                                        Call buchen
                                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" className="-rotate-90 stroke-white group-hover:stroke-black">
                                            <g strokeWidth="1.5" fill="none" fillRule="evenodd">
                                                <path d="M13 3.77V13H3.77M13 13 1 1" />
                                            </g>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="enable-animation mt-8">
                    <div className="relative flex overflow-hidden select-none gap-4">
                        <p className="marquee__content text-[#f5f5f5] whitespace-nowrap marquee-text pr-4">Building better ecommerce for people</p>
                        <p aria-hidden="true" className="marquee__content text-[#f5f5f5] whitespace-nowrap marquee-text">Building better ecommerce for people</p>
                    </div>
                </div>
                <div className="container flex py-8 justify-between">
                    <div>
                        <p className="font-base">
                            &copy;
                            {' '}
                            {today.getFullYear()}
                            {' '}
                            {title}
                        </p>
                    </div>
                    <ul className="font-base">
                        <li>
                            <Link className="relative inline-block underline underline-offset-3" to="/impressum/">Impressum</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
